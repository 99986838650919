<template>
  <v-app>
    <v-container fluid class="my-4 google-font">
      <v-layout align-center justify-center row>
        <v-flex xs12 lg8 xl6>
          <v-card>
            <RequestQuote
              headerText="Request a Free BugSeq Quote"
              headerSubtext="We will contact you to discuss your needs and send tailored pricing"
              :showVolume="true"
              cta="Get a Free Quote"
            />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import RequestQuote from "@bugseq-site/www/src/components/pricing/RequestQuote.vue";

@Component({
  metaInfo() {
    return {
      title: "Quote",
    };
  },
  components: {
    RequestQuote,
  },
})
export default class QuotePage extends Vue {}
</script>
